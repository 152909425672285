import React, { useState, useEffect } from "react";
import "./Analysis.scss";
import Analysing from "../../assets/Analysing.svg";
import { useNavigate } from "react-router-dom";

export default function Analysis() {
  const navigate = useNavigate();

  const [analysed, setAnalysed] = useState([]);
  const [analysing, setAnalysing] = useState([]);
  const [toAnalyse, setToAnalyse] = useState([
    "Analysing your details...",
    "Analysing your perspective...",
    "Analysing your responses...",
    "Analysing your data...",
    "Preparing your metrics...",
  ]);

  useEffect(() => {
    if (analysed.length >= 5) navigate("/conclusion");

    setTimeout(() => {
      const newDiv = toAnalyse[0];
      setAnalysed(analysing.slice(0));
      setAnalysing([...analysing, newDiv]);
      setToAnalyse(toAnalyse.slice(1));
    }, 3000);
  });

  return (
    <div className="analysis__container">
      <div className="analysis__logo" />
      <div className="analysis__content">
        <div className="analysis__text">
          Wait while we prepare your stats...
        </div>

        <div className="analysis_box">
          <div id="analysed">
            {analysed.length < 6 &&
              analysed.map((item, index) => {
                return (
                  <div key={index}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      fill="currentColor"
                      className="bi bi-check-all"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                    </svg>
                    {item}
                  </div>
                );
              })}
          </div>
          <div id="analysing">
            <img src={Analysing} alt="analysing your details..." />
            {analysing[analysing.length - 1]}
          </div>
          <div id="to_analysing">
            <div>
              {toAnalyse.map((item, index) => {
                return (
                  <div key={index}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      fill="currentColor"
                      className="bi bi-check-all"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                    </svg>
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
