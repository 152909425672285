import React from 'react'
import './Footer.scss'
function Footer() {
  return (
    <div className="Footer__Container">
    <div className="Footer__Content">
      <ul>
        <li><a href="https://nextcaller.ai/Terms-Of-Services/">Terms and Conditions</a></li>
        <li><a href="https://nextcaller.ai/Privacy-Policy/">Privacy Policy</a></li>
        <li><a href="#">Copyrights @ Nextcaller</a></li>
      </ul>
    </div>
  </div>
  )
}

export default Footer