import React from 'react'
import PayCard from '../Card/PayCard'

const paymentHandler = (details, data) => {
  fetch("https://buy.stripe.com/test_dR64is0Ll51ybMAeUX", {
    method: 'POST', 
      mode: 'cors', 
     
}
  )
}

function PaymentPage() {
  return (
    <section>
    
    <stripe-buy-button
  buy-button-id="buy_btn_1NAVCwSAVsF8iPirPjaJrFKO"
  publishable-key="pk_test_51NAUN6SAVsF8iPiraEKaVY7PwJdYbsbw0zEEObabnHS41Uaw6lezd4PU1494hqj7CZWMjOMHk5j0XsWUIqISL6e200YJsm637e"
>
</stripe-buy-button>
    <PayCard amount={100} duration={1} onClick={paymentHandler} value={'sil'}/>
    
    </section>
 
  )
}

export default PaymentPage