import React from "react";
import "./payCard.scss";
import purple from "../../assets/purple.svg";
import { useState, useEffect, useRef } from "react";
import { useContext } from "react";
import userContext from "../../userContext";
import firebase from "firebase";

import Line from "./line";
import Timer from "../../utils/Helper/Timer";
import storeAnswerInFirebase from "../../utils/Helper/FirebaseStoreTrack";

function PayCard(props) {
  // The state for our timer

  const [timerFinished, setTimerFinished] = useState(false);
  const userContextData = useContext(userContext);

  const handleCardClick = () => {
    userContextData.addTrack("Buy Now Clicked");
    const userData = {
      tracks: userContextData.tracks,
    };
    if (userContextData.tracks.length != 0) {
      storeAnswerInFirebase(userData, userContextData.sessionId);
      userContextData.removeTrack();
    }
    window.location.href = props.paymentLink;
  };

  const buttonStyle = {
    background: props.background,
  };
  console.log(userContextData.tracks);
  return (
    <div className="paycard__container" onClick={handleCardClick}>
      {props.tag ? (
        <div className="paycard__tag" style={buttonStyle}>
          <p className="paycard__tagContent">{props.tag}</p>
        </div>
      ) : (
        ""
      )}
      {props.recommended && !timerFinished ? (
        <div className="paycard__planContainer">
          <p className="paycard__planTimer">
            <Timer initialTime={15 * 60} />
          </p>
        </div>
      ) : (
        ""
      )}
      <div className="paycard__planName">
        <p className="paycard__planTitle1">{props.duration}</p>
      </div>

      <div className="paycard__content">
        <Line
          background={props.background}
          content={props.para1}
          recommended={props.recommended}
        />
        <Line
          background={props.background}
          content={"All features are unlocked in this plan"}
        />

        <Line background={props.background} discount={props.discount} />
      </div>

      <p className="paycard__button" style={buttonStyle}>
        <p>{props.price}</p>
        <span>{props.cutoff}</span>
      </p>
    </div>
  );
}

export default PayCard;
