import { Route, Routes } from "react-router-dom";
import PaymentPage from "./components/PaymentPage/PaymentPage";
import Survey from "./components/Survey/Survey";
import React from "react";
import Conclusion from "./components/Conclusion/Conclusion";
import Newsletter from "./components/Newslater/Newsletter";
import Hero from "./components/Hero/Hero";
import Analysis from "./components/Anaylsis/Analysis";
import UserInfoCard from "./components/Card/UserInfoCard";
import UserInfo from "./utils/UserInfo/UserInfo";
import NextCard from "./components/Card/NextCard";
import UserProvider from "./UserProvider";

function App() {
  return (
    <UserProvider>
      <Routes>
        <Route path="/" element={<Survey />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/analyse" element={<Analysis />} />
        <Route path="/conclusion" element={<Conclusion />} />
        {/* <Route path="/success" element={<Success />} /> */}
        <Route path="/newsletter" element={<Newsletter />} />
        <Route path="/plan" element={<Hero />} />
      </Routes>
    </UserProvider>
  );
}

export default App;
