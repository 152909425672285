import React from "react";
import "./faq.scss"
export default function FAQ(props) {
  return (
    <div className="wrapper">
    <div className="collapsible">
      <input type="checkbox" id={props.id} />
      <label htmlFor={props.id}>
        {props.question ? props.question : "No Question defined"}
        <div className="arrow" />
      </label>

      <div className="collapsible-text">
        {/* <h2>{props.heading ? props.heading : "No Heading defined"}</h2> */}
        <p>{props.para ? props.para : "No Para defined"}</p>
       
      </div>
    </div>
    </div>
  );
}