import './TestinomialCard.scss'

import React from 'react'

function TestinomialCard(props) {
  return (
    <div className="testiCard">
        <div className="testiUpper">
            <img src={props.src} alt="" />
            <p>{props.name}<br/>{props.stars}</p>

        </div>
        <div className="testiText">
            <p>{props.test}</p>
        </div>
    </div>
  )
}

export default TestinomialCard