import React, { useState, useEffect, useRef } from "react";

function Timer({ initialTime, onTimerFinish }) {
  const [timer, setTimer] = useState(initialTime);
  const [timerFinished, setTimerFinished] = useState(false);
  const timerRef = useRef(null);

  const getTimeRemaining = (endTime) => {
    const total = Date.parse(endTime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return { total, minutes, seconds };
  };

  const startTimer = (endTime) => {
    const { total, minutes, seconds } = getTimeRemaining(endTime);
    if (total >= 0) {
      setTimer(
        `${minutes > 9 ? minutes : "0" + minutes}:${
          seconds > 9 ? seconds : "0" + seconds
        }`
      );
    } else {
      setTimerFinished(true);
      onTimerFinish && onTimerFinish();
    }
  };

  const clearTimer = (endTime) => {
    setTimer(initialTime);
    setTimerFinished(false);

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      startTimer(endTime);
    }, 1000);
  };

  useEffect(() => {
    const deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + initialTime);
    clearTimer(deadline);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [initialTime]);

  return (
    <div>
      {!timerFinished && (
        <p>
          The offer expires in{" "}
          <span style={{ color: "red", fontWeight: "400" }}>{timer}m</span>
        </p>
      )}
    </div>
  );
}

export default Timer;
