import React from "react";
import "./ConclusionCard.css";
import { IKImage } from "imagekitio-react";
function ConclusionCard(props) {
  console.log(props.url);
  return (
    <div className="conclusionCard__container">
      <div className="conclusionCard__heading">{props.qualities}</div>
      <div className="conclusionCard__content">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IKImage
            urlEndpoint="https://ik.imagekit.io/7jhzmda5xn/nextcaller"
            path={props.path}
            width="80"
            height="80"
          />
        </div>
        <span className="conclusion_para">
          <p>{props.content}</p>
        </span>
      </div>
    </div>
  );
}

export default ConclusionCard;
