export const DummyQuestions = [
  {
    prompt: "What describes you?",
    answers: [
      { id: "1", value: "student" },
      { id: "2", value: "Employee" },
      { id: "3", value: "Entrepreneur" },
    ],
    isMultiple: "false",
  },
  {
    prompt: "How often are you receiving spam calls?",
    answers: [
      { id: "4", value: "less than 10 calls each day" },
      { id: "5", value: "10 - 30 calls each day" },
      { id: "6", value: "1- 4 calls per hour" },
      { id: "7", value: "4 - 10 calls/hour" },
      { id: "8", value: "more than 10 calls per hour" },
    ],
    isMultiple: "false",
  },
  {
    prompt: "What type of calls do you receive mostly??",
    answers: [
      { id: "9", value: "Fraud Calls" },
      { id: "10", value: "Telemarketing Calls" },
      { id: "11", value: "Network provider calls" },
      { id: "12", value: "Unknown numbers" },
    ],
    isMultiple: "true",
  },
  {
    prompt:
      "Are these calls annoying while you are working or enjoying your family time?",
    answers: [
      { id: "12", value: "Yes" },
      { id: "13", value: "No" },
    ],
    isMultiple: "false",
  },
  // {
  //   prompt: "What type of calls cause your distraction the most??",
  //   answers: [
  //     { id: "14", value: "Spams" },
  //     { id: "15", value: "Necessary/work calls" },
  //     { id: "16", value: "Friends/family" },
  //   ],
  //   isMultiple: "true",
  // },
  {
    prompt: "How do you deal with the calls?",
    answers: [
      { id: "17", value: "Use a call blocking app" },
      { id: "18", value: "Silent/aeroplane mode" },
      { id: "19", value: "Cut calls each time" },
    ],
    isMultiple: "true",
  },
  {
    prompt: "What features would you like your app to have if given a choice?",

    answers: [
      { id: "20", value: "Send autoreplies" },
      { id: "21", value: "Block calls during working or enjoying family time" },
      { id: "23", value: "Whitelist" },
    ],
    isMultiple: "true",
  },
  {
    prompt: "Would you like a customised solution for each type of call?",
    answers: [
      { id: "24", value: "Yes" },
      { id: "25", value: "NO" },
    ],
    isMultiple: "false",
  },
  {
    prompt:
      "On a scale of 1-10 how much are you frustrated about too much calls during vacation?",
    answers: [
      { id: "26", value: "Yes" },
      { id: "27", value: "No" },
    ],
    isMultiple: "false",
  },
];
