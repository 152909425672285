import React from 'react'
import './NextCard.css'
function NextCard(props) {
  return (
    <div className='NextCard__container'>
        <div className="NextCard__title">
        {props.title}
        </div>
        <div className="NextCard__para">
        {props.para}
        </div>
    </div>
  )
}

export default NextCard