import "./Playstore.scss";
import PlayBtn from "../../assets/PlayBtn.svg";
import PlayDetails from "../../assets/PlayDetails.svg";

export default function PlayStore() {
  return (
    <>
      <div className="playstore_div">
        <div>
          <div className="app_name">Nextcaller : Smart call blocker</div>

          <p>We are available on play store!</p>
          <div className="PlayDetails">
            <img src={PlayDetails} alt="PlayDetails" />
          </div>
          <a
            href="https://play.google.com/store/apps/details?id=com.prodenzo.neXtcaller"
            target="_blank"
            rel="noreferrer"
            className="play_btn"
          >
            <img src={PlayBtn} alt="PlayBtn" />
          </a>
        </div>
        <div className="video_body">
          <div className="circle"></div>
          <iframe
            className="youtube_video"
            width="280"
            height="500"
            src="https://www.youtube.com/embed/KkdTQvOKWV0"
            title="Nextcaller work mode"
            frameborder="0"
            autoPlay={true}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </>
  );
}
