import firebase from "firebase";


const firebaseConfig = {
    apiKey: "AIzaSyCRplvbvEmY88Ivbv_NOMHxqXkPm9Q7Isg",
    authDomain: "feedback-nextcaller.firebaseapp.com",
    databaseURL: "https://feedback-nextcaller-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "feedback-nextcaller",
    storageBucket: "feedback-nextcaller.appspot.com",
    messagingSenderId: "879890734862",
    appId: "1:879890734862:web:1dd10d7642f3c573f1c15f",
    measurementId: "G-06Z1LTRE9D"
  };

firebase.initializeApp(firebaseConfig);
export default firebase;