import React from "react";
import Progress from "react-progressbar";
import "./Survey.css";
import Logo from "../../assets/image/NameLogo.png";
import Card from "../Card/Card";
import Testinomial from "../Testinomial/Testinomial";
import { useState } from "react";
import { DummyQuestions } from "../../DummyQuestion";
import { useNavigate } from "react-router-dom";
import Scale from "../../utils/Scale/Scale";
import UserInfo from "../../utils/UserInfo/UserInfo";
import { useContext } from "react";
import userContext from "../../userContext";
import { useEffect } from "react";
function Survey() {
  const navigate = useNavigate();
  const [currentQuestion, setcurrentQuestion] = useState(0);
  const [increment, setIncrement] = useState(0);
  const [showTestinomial, setshowTestinomial] = useState(false);
  const [choosen, setChoosen] = useState(true);
  const [count, setCount] = useState(0);
  const [id, setId] = useState(0);
  const [chosenAnswers, setChosenAnswers] = useState([]);
  const [scaleValue, setscaleValue] = useState("");
  const userContextData = useContext(userContext);
  console.log(userContextData);

  // useEffect(() => {
  //   const isFirstTime = localStorage.getItem("firstTime");
  //   if (isFirstTime) {
  //     navigate("/hero"); // Replace "/hero-section" with your desired route
  //   }
  // }, []);

  if (currentQuestion + 1 === DummyQuestions.length) {
    const button = document.getElementById("choosen");
    button.classList.add("choosen");
  }

  const style = {
    backgroundColor: "#CDCDCD",
    height: 3,
  };
  // const trigger = () => {
  //   setChoosen('true')
  // }
  const testinomialHandler = () => {
    setshowTestinomial(false);
    setcurrentQuestion(currentQuestion + 2);
  };

  const questionHandler = () => {
    if (currentQuestion === 2) {
      setshowTestinomial(true);
    } else if (DummyQuestions.length === currentQuestion + 1) {
      navigate("/analyse");
    } else {
      setcurrentQuestion(currentQuestion + 1);
      setCount(0);
      setId(0);

      const button = document.getElementById("choosen");
      button.classList.remove("choosen");

      setIncrement(increment + 100 / DummyQuestions.length);
    }
  };
  function selectHandler(event) {
    const selectedAnswerId = event.target.id;
    const selectedAnswerValue = DummyQuestions[currentQuestion].answers.find(
      (answer) => answer.id === selectedAnswerId
    ).value;

    // Check if the answer is already chosen and remove it if it is, or add it if not
    if (chosenAnswers.includes(selectedAnswerValue)) {
      setChosenAnswers(
        chosenAnswers.filter((answer) => answer !== selectedAnswerValue)
      );
    } else {
      setChosenAnswers([...chosenAnswers, selectedAnswerValue]);
      userContextData.addChosenAnswer(selectedAnswerValue);
    }

    if (DummyQuestions[currentQuestion].isMultiple === "false") {
      // if(count === 0){
      //   setId(event.target.id);
      //   const answerCard = document.getElementById(event.target.id);
      //   answerCard.classList.add("selected");
      //   setChoosen(true);
      //   setCount(1);
      // }
      // if (count === 1) {
      //   const oldAnswerCard = document.getElementById(id);
      //   oldAnswerCard.classList.remove("selected");
      //   const answerCard = document.getElementById(event.target.id);
      //   answerCard.classList.add("selected");
      //   setChoosen(true);
      //   setId(event.target.id);
      //   setCount(1);
      // }
      questionHandler();
    } else {
      const answerCard = document.getElementById(event.target.id);
      answerCard.classList.toggle("selected");
      setChoosen(true);
    }
    //For Button
    if (choosen) {
      const button = document.getElementById("choosen");
      button.classList.add("choosen");
    }
  }
  const answersCards = DummyQuestions[currentQuestion].answers.map(
    (item, index) => {
      return (
        <Card id={item.id} onSelect={selectHandler} key={item.id}>
          {item.value}
        </Card>
      );
    }
  );
  const func = (event) => {
    setChosenAnswers([...chosenAnswers, event.content]);
    userContextData.addChosenAnswer(event.content);
    // const button = document.getElementById("choosen");
    // button.classList.add("choosen");
    questionHandler();
  };
  const scaleFunction = (event) => {};
  const renderhandler = () => {
    if (currentQuestion + 1 !== DummyQuestions.length) {
      if (currentQuestion + 1 === 1) {
        return <UserInfo onSelect={func} />;
      }
      return answersCards;
    } else {
      return <Scale scaleValue={scaleValue} setscaleValue={setscaleValue} />;
    }
  };

  if (scaleValue !== "") {
    setChosenAnswers([...chosenAnswers, scaleValue]);
  }
  console.log(chosenAnswers);

  return (
    <div className="survey__container">
      {showTestinomial ? (
        <Testinomial next={testinomialHandler} />
      ) : (
        <>
          <div className="survey__main">
            <div className="survey__progress">
              <span className="survey__heading">
                <img src={Logo} height={60} width={270} alt="" />
              </span>
              <span className="survey__counter">
                {currentQuestion + 1}/{DummyQuestions.length}
              </span>
              <span>
                <Progress
                  completed={100 / DummyQuestions.length + increment}
                  style={style}
                  height={3}
                  className="probar"
                />
              </span>
            </div>
            <div className="survey__question">
              <span className="survey__question">
                {DummyQuestions[currentQuestion].prompt}
              </span>
            </div>
            <div className="survey__description">
              {DummyQuestions[currentQuestion].isMultiple === "true"
                ? "select one or more option(s)"
                : "select a single option"}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="survey__options">{renderhandler()}</div>
            </div>

            <div className="survey__button" id="choosen">
              <button onClick={(event) => questionHandler(event)}>
                Continue
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Survey;
