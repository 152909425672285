import React from "react";
import "./Conclusion.css";
import { useNavigate } from "react-router-dom";
import ConclusionCard from "../Card/ConclusionCard";
import { useEffect } from "react";
import userContext from "../../userContext";
import { useContext } from "react";
import firebase from "firebase";
import storeAnswerInFirebase from "../../utils/Helper/FirebaseStoreTrack";
// required parameter to fetch images

function Conclusion() {
  const userContextData = useContext(userContext);

  const uniqueID = userContextData.sessionId;
  useEffect(() => {
    userContextData.addTrack("Survey Completed");
  }, []);

  const userData = {
    tracks: userContextData.tracks,
  };
  console.log(userContextData.tracks.length);
  if (userContextData.tracks.length != 0) {
    storeAnswerInFirebase(userData, uniqueID);
    userContextData.removeTrack();
  }
  console.log(userContextData.tracks);
  const navigate = useNavigate();
  return (
    <div className="conclusion__container">
      <div className="conclusion__logo" />
      <div className="conclusion__content">
        <div className="conclusion__text">Conclusion</div>
        <div className="conclusion__innerContent">
          <span>Based on the survey we have concluded that you are having</span>
          <span>
            {" "}
            these qualities in you and lets see how we can help you...
          </span>
        </div>
        <div className="conclusion__cards">
          <div className="conclusion__items">
            <ConclusionCard
              path={"/bowdown.png"}
              qualities={"Responsible"}
              content={
                "You love to plan, prioritize and organise your works. You know the importance of balance between various works"
              }
            />
          </div>
          <div className="conclusion__items">
            <ConclusionCard
              path={"/head.png"}
              qualities={"Dedicated"}
              content={
                "You do not want any disturbance while you are working and thus have lot of dedication"
              }
            />
          </div>
          <div className="conclusion__items">
            <ConclusionCard
              path={"/sad.png"}
              qualities={"Frustrated"}
              content={
                "You get distracted that leads to be frusturated. You want an effective solution that help you reduce frusturation"
              }
            />
          </div>
          <div className="conclusion__items">
            <ConclusionCard
              path={"/what.png"}
              qualities={"Workaholic"}
              content={
                "You like to work and thus have a tight work schedule, where you want to be 100% productive"
              }
            />
          </div>
        </div>
        <div className="conclusion__button">
          <button onClick={() => navigate("/plan")}>See My Plans</button>
        </div>
      </div>
    </div>
  );
}

export default Conclusion;
