import React from "react";
import "./Testinomial.css";
function Testinomial(props) {
  return (
    <div className="testinomial__container">
      <div className="testinomial__logo" />

      <div className="testinomial__content">
        <div className="left" />
        <div className="right">
          <div className="content">
            <h3>George Miller!</h3>
            <span>
              Nextcaller is a game-changer! With its user-friendly interface, I
              can easily block calls during specific hours, boosting my
              productivity. The whitelist feature ensures important calls always
              get through. I just became more focused and created a work-life
              balance with Nextcaller!
            </span>
          </div>
        </div>
        <div className="testinomial__button">
          <button onClick={props.next}>Continue</button>
        </div>
      </div>
    </div>
  );
}

export default Testinomial;
