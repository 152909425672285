import React, { useState, useEffect } from "react";
import userContext from "./userContext";
import { v4 as uuidv4 } from "uuid"; // Import uuid

const UserProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState("");

  useEffect(() => {
    // Check if a session ID is already present in local storage
    let storedSessionId = localStorage.getItem("sessionId");

    // If not, generate a new one and store it in local storage
    if (!storedSessionId) {
      storedSessionId = uuidv4();
      localStorage.setItem("sessionId", storedSessionId);
    }

    // Set the session ID
    setSessionId(storedSessionId);
  }, []); // Empty dependency array to run the effect only once

  const [chosenAnswer, setChosenAnswer] = useState([]);
  const [tracks, setTracks] = useState([]);

  const addChosenAnswer = (answer) => {
    setChosenAnswer((prevChosenAnswers) => [...prevChosenAnswers, answer]);
  };

  const clearChosenAnswers = () => {
    setChosenAnswer([]);
  };

  const addTrack = (text) => {
    setTracks((prevTracks) => [...prevTracks, text]);
  };
  const removeTrack = () => {
    setTracks([]);
  };

  const contextValue = {
    sessionId,
    chosenAnswer,
    tracks,
    addTrack,
    addChosenAnswer,
    clearChosenAnswers,
    removeTrack,
  };

  return (
    <userContext.Provider value={contextValue}>{children}</userContext.Provider>
  );
};

export default UserProvider;
