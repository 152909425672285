import React from "react";
import "./card.css";

function Card(props) {
  const selectHandler = props.onSelect;
  return (
    <div
      className="option__container"
      onClick={(event) => {
        selectHandler(event);
      }}
      id={props.id}
    >
      {props.children}
    </div>
  );
}

export default Card;
