import React from "react";
import "./UserInfo.scss";
import UserInfoCard from "../../components/Card/UserInfoCard";

const userInfoOptions = [
  { id: "1", content: "Student", path: "./Student.png" },
  { id: "2", content: "Employee", path: "./Employee.png" },
  { id: "3", content: "Entrepreneur", path: "./Enterpreneur.png" },
];

function UserInfo(props) {
  const handleOptionSelect = (option) => {
    // Call the provided onSelect function from props
    props.onSelect(option);
  };

  return (
    <div className="userInfo__container">
      {userInfoOptions.map((option) => (
        <span
          className="userInfo__cards"
          key={option.id}
          onClick={() => handleOptionSelect(option)}
        >
          <UserInfoCard
            id={option.id}
            content={option.content}
            path={option.path}
          />
        </span>
      ))}
    </div>
  );
}

export default UserInfo;
