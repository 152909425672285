import React from "react";
import "./line.scss";
import check from "../../assets/check.svg";
import checkWhite from "../../assets/checkWhite.svg";

function Line(props) {
  const circleStyle = {
    background: props.background,
  };
  return (
    <div className="line__container">
      <div className="line__circle" style={circleStyle}>
        <img src={checkWhite} alt="" />
      </div>
      {props.recommended ? (
        <p className="line__contentRecommended">{props.content}</p>
      ) : (
        <p className="line__content">{props.content}</p>
      )}
      {props.discount ? (
        <p className="line__content">
          <span>{props.discount}</span> in this plan
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

export default Line;
