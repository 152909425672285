function generateRandomNumber(startRange, endRange) {
  // Ensure that the startRange is less than or equal to endRange
  if (startRange > endRange) {
    [startRange, endRange] = [endRange, startRange];
  }

  // Generate a random number within the specified range
  return Math.floor(Math.random() * (endRange - startRange + 1)) + startRange;
}

function generateRandomDoubleNumber(startRange, endRange) {
  // Ensure that the startRange is less than or equal to endRange
  if (startRange > endRange) {
    [startRange, endRange] = [endRange, startRange];
  }

  // Generate a random number within the specified range with two decimal places
  let randomNumber = (
    Math.random() * (endRange - startRange) +
    startRange
  ).toFixed(2);

  return parseFloat(randomNumber);
}

export { generateRandomNumber, generateRandomDoubleNumber };
